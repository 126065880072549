import { useAccountStore } from '~/store/account'
import { MENU_WITHDRAW, MENU_WITHDRAW_DESKTOP } from '~/constants/menu'
import { IMenuWithDraw } from '~/types/menu.type'

export const useWithdrawMenu = () => {
  const accountStore: any = useAccountStore()

  const menuWithdraw = ref<IMenuWithDraw[]>(MENU_WITHDRAW_DESKTOP)
  const menuWithdrawMB = ref<IMenuWithDraw[]>(MENU_WITHDRAW)

  const updateDataMenuWithdraw = () => {
    return MENU_WITHDRAW_DESKTOP.map((item: IMenuWithDraw) => {
      let isMaintain: boolean = false

      switch (item.key) {
        case 'cardList': {
          const cardList = { ...accountStore?.cardList }
          delete cardList.ZING
          isMaintain = !Object.values(cardList).some((card: any) => card.status === 1)
          break
        }
        case 'crypto': {
          isMaintain = !Object.values(accountStore.crypto_v2).some((currency: any) => currency.is_support_withdraw)
          break
        }
      }

      return {
        ...item,
        isMaintain
      }
    })
  }

  const updateDataMenuWithdrawMB = () => {
    return MENU_WITHDRAW.map((item: IMenuWithDraw) => {
      let isMaintain: boolean = false

      switch (item.key) {
        case 'cardList': {
          const cardList = { ...accountStore?.cardList }
          delete cardList.ZING
          isMaintain = !Object.values(cardList).some((card: any) => card.status === 1)
          break
        }
        case 'crypto': {
          isMaintain = !Object.values(accountStore.crypto_v2).some((currency: any) => currency.is_support_withdraw)
          break
        }
      }

      return {
        ...item,
        isMaintain
      }
    })
  }

  onMounted(() => {
    menuWithdraw.value = updateDataMenuWithdraw()
    menuWithdrawMB.value = updateDataMenuWithdrawMB()
  })

  return {
    menuWithdraw,
    menuWithdrawMB
  }
}
